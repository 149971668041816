<template>
  <div id="app">
    <b-navbar toggleable="lg" type="light" variant="light" sticky="true">
      <b-navbar-brand href="#"><img src="/img/Logo Black.png" alt="hrnautocare" class="nav-img-custom"></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item href="#">
              <router-link to="/">HOME</router-link>
            </b-nav-item>
            <b-nav-item href="#">
              <router-link to="/servis-home">OUR PACKAGE SERVICES</router-link>
            </b-nav-item>
            <b-nav-item href="#">
              <router-link to="/lokasi">LOCATION</router-link>
            </b-nav-item>
            <b-nav-item href="#">
              <router-link to="/tentang-hrn">ABOUT</router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
  export default {
    methods: {
      HomeRouter() {
        this.$router.push({
          name: "Home"
        });
      },
    },
    name: 'App',
    components: {

    }
  }
</script>

<style>
  body {
    background-color: #171717 !important;
  }

  nav a.router-link-exact-active {
    color: white !important;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  .navbar.navbar-light.bg-light {
    background-color: #f8bc06 !important;
  }

  .nav-img-custom {
    width: 5rem;
    height: 10%;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .b-nav-item {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
</style>