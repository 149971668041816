import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LokasiView from '../views/LokasiView.vue'
import TentangHRNView from '../views/TentangHRNView.vue'
import PaketServisHomeView from '../views/PaketServisHomeView.vue'
import PaketDetailingView from '../views/PaketDetailingView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/lokasi',
    name: 'lokasi',
    component: LokasiView
  },
  {
    path: '/tentang-hrn',
    name: 'tentang-hrn',
    component: TentangHRNView
  },
  {
    path: '/servis-home',
    name: 'servis-home',
    component: PaketServisHomeView
  },
  {
    path: '/detailing',
    name: 'detailing',
    component: PaketDetailingView
  },
  {
    path: '/paket-servis',
    name: 'paket-servis',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PaketServisView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
