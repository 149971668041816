<template>
  <div class="home">
    <!-- FLOATING BUTTON -->
    <a href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare" class="float"
      target="_blank">
      <b-img center src="/img/whatsapp.svg" width="40px" height="40px" alt="Center image" class="my-float"></b-img>
    </a>
    <!-- END FLOATING BUTTON -->
    <!-- BANNER SESSION -->
    <div class="jumbotron-blur mb-5">
      <div class="jumbo-content">
        <div class="content">
          <b-img src="/img/hrn logo.png" class="mb-4" fluid alt="Fluid image" width="200px" height="200px"></b-img><br>
          <p class="h2"><b>FOR YOUR SPECIAL CAR</b></p>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Selamat%20datang%20di%20HRN%20AutoCare%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking.%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A%0D%0AJenis%20Service%20%20%20%20%20%20%20%20%20%20%20%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
      </div>
    </div>
    <!-- END BANNER SESSION -->
    <!-- ABOUT US -->
    <div>
      <div class="container text-light">
        <h2 class="m-5"><b>PROMO GRAND OPENING DISCOUNT UP TO 30% !!!</b></h2>
        <center>
          <div class="mb-5 custom-container">
            <b-carousel id="carousel-1" v-model="slide" :interval="3000" controls indicators background="#ababab"
              img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd" class="mb-3">
              <!-- Slides with image only -->
              <b-carousel-slide img-src="/img/paket/PAKET REM.png"></b-carousel-slide>
              <!-- Slides with image only -->
              <b-carousel-slide img-src="/img/paket/WARRIOR.png"></b-carousel-slide>
              <!-- Slides with image only -->
              <b-carousel-slide img-src="/img/paket/DIESEL.png"></b-carousel-slide>
              <!-- Slides with image only -->
              <b-carousel-slide img-src="/img/paket/MANOL 1.png"></b-carousel-slide>
            </b-carousel>
            <router-link to="/paket-servis">
              <b-button variant="outline-warning" class="mb-5">Lihat Promo</b-button>
            </router-link>
          </div>
        </center>
      </div>
    </div>
    <!-- END ABOUT US -->
    <!-- IMAGE KNOWLEDGE -->
    <b-jumbotron>
      <div class="container text-light">
        <h2 class="m-5"><b>Ayo!! Tunggu apalagi??<br>
            Rawat kendaraan mu yang sudah banyak berjasa buat kamu!!</b></h2>
        <div class="mb-5">
          <div class="row">
            <div class="col-lg-4 mb-3">
              <b-img thumbnail fluid src="/img/knowledge/knowledge servis rutin.png" alt="Image 1"></b-img>
            </div>
            <div class="col-lg-4 mb-3">
              <b-img thumbnail fluid src="/img/knowledge/knowledge healing.png" alt="Image 1"></b-img>
            </div>
            <div class="col-lg-4 mb-3">
              <b-img thumbnail fluid src="/img/knowledge/knowledge kampas rem.jpg" alt="Image 1"></b-img>
            </div>
          </div>
        </div>
      </div>
    </b-jumbotron>
    <!-- END IMAGE KNOWLEDGE -->
    <!-- TIPS DAN INFO HRN -->
    <div class="container text-light">
      <h2 class="m-5"><b>Tips & Info HRN Autocare</b></h2>
      <!-- WEB VERSION -->
      <div class="mb-5 d-none d-lg-block d-xl-block">
        <div class="d-flex justify-content-center">
          <div class="bg-grey">
            <b-img src="/img/tips/3.png" width="555px" alt="Responsive image"></b-img>
          </div>
          <div class="bg-grey">
            <div class="d-flex h-100 flex-column">
              <div class="container mt-auto mb-auto text-left">
                <h5><b>Bahaya Minyak Rem Tercampur Air</b></h5>
                <p>Kalau sudah tercampur air, titik didih minyak rem akan
                  menurun. Ketika proses pengereman, minyak rem akan panas karena gesekan kampas rem. Titik didih
                  panas minyak rem yang tinggi menjaga tekanan hidrolis yang diperlukan untuk menekan kampas rem.
                  Karena titik didih minyak rem turun karena tercampur air, tekanan hidrolisnya jadi berkurang dan
                  berarti rem mobil kurang pakem, bahkan kalau sudah parah bisa-bisa jadi blong.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="bg-grey">
            <div class="d-flex h-100 flex-column">
              <div class="container mt-auto mb-auto text-left">
                <h5><b>Berapa Waktu Ideal Mobil Harus di Checkup</b></h5>
                <p>Sesuai standar buku panduan mobil pada umumnya, checkup mobil dilakukan setelah 1.000 km pertama,
                  5.000 km, 10.000 km, 20.000 km, 30.000 km, dan seterusnya.
                  Checkup mobil juga bisa dilakukan berdasarkan waktu lama pemakaian. Misalkan apabila mobil digunakan
                  secara ideal maka waktu checkupnya biasanya 6 bulan sekali.
                </p>
              </div>
            </div>
          </div>
          <b-img src="/img/tips/2.png" width="555px" alt="Responsive image"></b-img>
        </div>
        <div class="d-flex justify-content-center">
          <b-img src="/img/tips/1.png" width="555px" alt="Responsive image"></b-img>
          <div class="bg-grey">
            <div class="d-flex h-100 flex-column">
              <div class="container mt-auto mb-auto text-left">
                <h5><b>Pentingya Gurah Mesin Pada Mobil Anda</b></h5>
                <p>Gurah mesin mobil dilakukan sebagai salah satu solusi untuk mengembalikan performa kendaraan.
                  Sederhananya, dengan prosedur gurah mesin, maka tumpukan deposit atau kerak karbon di ruang bakar
                  mobil akibat pemakaian dapat berkurang. Jika dibandingkan, gurah mesin lebih hemat daripada biaya
                  turun mesin mobil.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END WEB VERSION -->
      <!-- MOBILE VERSION -->
      <div class="mb-5 d-block d-lg-none d-xl-none">
        <div class="bg-grey">
          <div class="d-flex h-100 flex-column">
            <div class="container text-left">
              <h5 class="container mt-5"><b>Bahaya Minyak Rem Tercampur Air</b></h5>
              <p class="container mb-5">Kalau sudah tercampur air, titik didih minyak rem akan
                menurun. Ketika proses pengereman, minyak rem akan panas karena gesekan kampas rem. Titik didih
                panas minyak rem yang tinggi menjaga tekanan hidrolis yang diperlukan untuk menekan kampas rem.
                Karena titik didih minyak rem turun karena tercampur air, tekanan hidrolisnya jadi berkurang dan
                berarti rem mobil kurang pakem, bahkan kalau sudah parah bisa-bisa jadi blong.
              </p>
            </div>
          </div>
          <b-img src="/img/tips/3.png" class="w-100" alt="Responsive image"></b-img>
        </div>
        <div class="bg-grey">
          <div class="d-flex h-100 flex-column">
            <div class="container mt-auto mb-auto text-left">
              <h5 class="container mt-5"><b>Berapa Waktu Ideal Mobil Harus di Checkup</b></h5>
              <p class="container mb-5">Sesuai standar buku panduan mobil pada umumnya, checkup mobil dilakukan setelah
                1.000 km pertama,
                5.000 km, 10.000 km, 20.000 km, 30.000 km, dan seterusnya.
                Checkup mobil juga bisa dilakukan berdasarkan waktu lama pemakaian. Misalkan apabila mobil digunakan
                secara ideal maka waktu checkupnya biasanya 6 bulan sekali.
              </p>
            </div>
          </div>
          <b-img src="/img/tips/2.png" class="w-100" alt="Responsive image"></b-img>
        </div>
        <div class="bg-grey">
          <div class="d-flex h-100 flex-column">
            <div class="container mt-auto mb-auto text-left">
              <h5 class="container mt-5"><b>Pentingya Gurah Mesin Pada Mobil Anda</b></h5>
              <p class="container mb-5">Gurah mesin mobil dilakukan sebagai salah satu solusi untuk mengembalikan
                performa kendaraan.
                Sederhananya, dengan prosedur gurah mesin, maka tumpukan deposit atau kerak karbon di ruang bakar
                mobil akibat pemakaian dapat berkurang. Jika dibandingkan, gurah mesin lebih hemat daripada biaya
                turun mesin mobil.
              </p>
            </div>
          </div>
          <b-img src="/img/tips/1.png" class="w-100" alt="Responsive image"></b-img>
        </div>
      </div>
      <!-- END MOBILE VERSION -->
    </div>
    <!-- END TIPS DAN INFO HRN -->
    <!-- WHY CHOOSE USE SESSION -->
    <b-jumbotron>
      <div class="container text-light">
        <h2 class="m-5"><b>Mager ke Bengkel ?</b></h2>
        <div class="mb-5 text-justify">
          <div class="d-flex h-100 flex-column">
            <div class="container mt-auto mb-auto">
              <center>
                <h3 class="mb-4">HRN Autocare punya layanan home service juga loh</h3>
                <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20HOME%20SERVICE%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking.%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
                  target="_blank">
                  <b-button variant="outline-warning">BOOK NOW</b-button>
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </b-jumbotron>
    <!-- END WHY CHOOSE USE SESSION -->
    <!-- WHY CHOOSE USE SESSION -->
    <div class="container text-light">
      <h2 class="m-5"><b>Mengapa Harus HRN Autocare</b></h2>
      <div class="row mb-5 text-justify">
        <div class="col-lg-6">
          <div class="d-flex h-100 flex-column">
            <div class="container mt-auto mb-auto">
              <p>HRN Autocare merupakan bengkel mobil terpercaya bergaransi yang menyediakan servis sesuai dengan
                kebutuhan anda. Dengan paket-paket hemat yang kami sediakan, pelanggan tidak perlu khawatir lagi
                dengan yang namanya hidden cost. Nikmati kemudahan perbaikan dan perawatan mobil anda bersama kami</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mechanic">
          <b-img thumbnail fluid src="/img/image1.png" alt="Image 1"></b-img>
        </div>
      </div>
    </div>
    <!-- WHY CHOOSE USE SESSION -->
    <b-jumbotron>
      <div class="container text-light">
        <h2 class="m-5"><b>Kenapa Harus Pilih HRN Autocare</b></h2>
        <div class="row mb-5">
          <div class="col-lg-6 mechanic">
            <b-img thumbnail fluid src="/img/image2.png" alt="Image 1"></b-img>
          </div>
          <div class="col-lg-6">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <p class="text-justify"><b>Mekanik Yang Handal</b></p>
                  <p class="text-justify">HRN Autocare mempunyai mekanik yang sudah berpengalaman pada bidang servis
                    mobil
                    dan mekanik kami sangat teliti dalam mengerjakan pekerjaannya</p>
                </div>
                <div class="col-lg-6">
                  <p class="text-justify"><b>Bengkel Terpercaya</b></p>
                  <p class="text-justify">HRN Autocare adalah bengkel yang terpercaya karena kami mempunyai mekanik yang
                    handal dan kami juga tidak hidden cost jadi pelangan tidak akan khawatir</p>
                </div>
                <div class="col-lg-6">
                  <p class="text-justify"><b>harga yang wajar</b></p>
                  <p class="text-justify">HRN Autocare memberikan harga yang sesuai dengan apa yang kita berikan kepaada
                    pelangan dan untuk detailnya selalu kita cantumkan di paket kita bisa dilihat di paket servis kami
                  </p>
                </div>
                <div class="col-lg-6">
                  <p class="text-justify"><b>Pelayanan yang cepat</b></p>
                  <p class="text-justify">HRN Autocare mempunyai pelayanan yang cepat dan juga sesuai dengan kebutuhan
                    pelangan. Untuk booking service kami meyediakan layanan whatsapp yang akan cepat dibalas oleh admin
                    dari kami</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 class="m-5"><b>Masih Kurang Yakin Takut Harganya Kemahalan ?? Harga Kami Dimulai Dari Ratusan Ribu aja loh!!
            Gapercaya Yuk Cek Aja Dibawah</b></h2>
      </div>
    </b-jumbotron>
    <!-- END WHY CHOOSE USE SESSION -->
    <!-- BANNER PAKET SERVIS -->
    <center>
      <div class="row custom-row">
        <div class="col-lg-6 col-md-6 col-sm-12 mb-3 custom-col">
          <div class="banner-image-servis-3 d-flex justify-content-center align-items-center">
            <div class="content text-center m-5">
              <h1 class="text-white"><b>SERVIS</b></h1>
              <router-link to="/paket-servis">
                <b-button variant="outline-warning">Lihat Paket Servis</b-button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6  mb-3 custom-col">
          <div class="banner-image-servis-4 d-flex justify-content-center align-items-center">
            <div class="content text-center m-5">
              <h1 class="text-white"><b>DETAILING</b></h1>
              <router-link to="/detailing">
                <b-button variant="outline-warning">Lihat Paket Servis</b-button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </center>
    <!-- END BANNER PAKET SERVIS -->
    <!-- SERVIS -->
    <div class="container text-light">
      <h2 class="m-5"><b>SERVIS KAMI</b></h2>
      <div class="row mb-5">
        <div class="container">
          <div class="row">
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/detailing.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Detailing</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/Service.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Service Berkala</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/overhaul.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Overhaul</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/tune up.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Tune Up</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/gurah mesin.svg" width="70px" height="70px" alt="Center image">
              </b-img>
              <p class="text-center"><b>Gurah Mesin</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/whell.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Rem dan Kaki-Kaki</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/injector.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Injector Cleaning</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/oil.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Ganti Oli</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/wind.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>AC Mobil</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/engine.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Service</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/transmisi.svg" width="70px" height="70px" alt="Center image"></b-img>
              <p class="text-center"><b>Transmisi</b></p>

            </div>
            <div class="col-6 col-md-4 col-lg-2">
              <b-img center class="mb-3" src="/img/power steering.svg" width="70px" height="70px" alt="Center image">
              </b-img>
              <p class="text-center"><b>Power Steering</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END SERVIS -->
    <!-- TESTIMONI -->
    <b-jumbotron>
      <div class="container text-light">
        <h2 class="mb-5"><b>TESTIMONI</b></h2>
        <VueSlickCarousel v-bind="settings">
          <div>
            <b-card class="mb-2 custom-card-body ml-2 mr-2">
              <b-card-header class="custom-card-header">
                <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/cebeDuZGeRQ?rel=0"
                  allowfullscreen style="max-width: 20rem;"></b-embed>
              </b-card-header>
              <div class="p-3">
                <b-card-text>
                  Kevin Surya Augustaf
                  <div class="d-flex justify-content-center">
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                  </div>
                  Some quick example text to build on the card title and make up the bulk of the card's content.
                </b-card-text>

                <b-button href="#" variant="warning">Go somewhere 1</b-button>
              </div>
            </b-card>
          </div>
          <div>
            <b-card class="mb-2 custom-card-body ml-2 mr-2">
              <b-card-header class="custom-card-header">
                <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/cebeDuZGeRQ?rel=0"
                  allowfullscreen style="max-width: 20rem;"></b-embed>
              </b-card-header>
              <div class="p-3">
                <b-card-text>
                  Kevin Surya Augustaf
                  <div class="d-flex justify-content-center">
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                  </div>
                  Some quick example text to build on the card title and make up the bulk of the card's content.
                </b-card-text>

                <b-button href="#" variant="warning">Go somewhere 2</b-button>
              </div>
            </b-card>
          </div>
          <div>
            <b-card class="mb-2 custom-card-body ml-2 mr-2">
              <b-card-header class="custom-card-header">
                <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/cebeDuZGeRQ?rel=0"
                  allowfullscreen style="max-width: 20rem;"></b-embed>
              </b-card-header>
              <div class="p-3">
                <b-card-text>
                  Kevin Surya Augustaf
                  <div class="d-flex justify-content-center">
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                  </div>
                  Some quick example text to build on the card title and make up the bulk of the card's content.
                </b-card-text>

                <b-button href="#" variant="warning">Go somewhere 3</b-button>
              </div>
            </b-card>
          </div>
          <div>
            <b-card class="mb-2 custom-card-body ml-2 mr-2">
              <b-card-header class="custom-card-header">
                <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/embed/cebeDuZGeRQ?rel=0"
                  allowfullscreen style="max-width: 20rem;"></b-embed>
              </b-card-header>
              <div class="p-3">
                <b-card-text>
                  Kevin Surya Augustaf
                  <div class="d-flex justify-content-center">
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3 mr-1" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                    <b-img class="mb-3" src="/img/star-solid.svg" width="15px" height="15px" alt="Center image">
                    </b-img>
                  </div>
                  Some quick example text to build on the card title and make up the bulk of the card's content.
                </b-card-text>

                <b-button href="#" variant="warning">Go somewhere 4</b-button>
              </div>
            </b-card>
          </div>
        </VueSlickCarousel>
      </div>
    </b-jumbotron>
    <!-- END TESTIMONI -->
    <!-- FOOTER -->
    <hr>
    <div class="container text-light">
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <p class="text-left"><b>WORKSHOP</b></p>
          <p class="text-left"><a class="workshop" href="https://goo.gl/maps/H55BkPURVjNNeesY9" target="_blank">Jl. Raya
              Parung - Ciputat No.88, RT.01/RW.01, Bojongsari Baru, Kec. Bojongsari, Kota Depok, Jawa Barat 16517</a>
          </p>
        </div>
        <div class="col-lg-4">
          <p class="text-left"><b>JAM BUKA</b></p>
          <p class="text-left">Setiap hari<br> 09:00 - 18:00 WIB</p>
        </div>
        <div class="col-lg-4">
          <p class="text-left"><b>CONTACT US</b></p>
          <p class="text-left"><a class="workshop" href="https://www.instagram.com/hrnautocare/" target="_blank">
              <div class="row">
                <div class="col-1 float-left">
                  <b-img center src="/img/instagram.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11 float-right">
                  hrnautocare
                </div>
              </div>
            </a>
          </p>
          <p class="text-left"><a class="workshop" href="mailto:admin@hrnautocare.com" target="_blank">
              <div class="row">
                <div class="col-1">
                  <b-img center src="/img/email.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11">
                  admin@hrnautocare.com
                </div>
              </div>
            </a>
          </p>
          <p class="text-left"><a class="workshop"
              href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare"
              target="_blank">
              <div class="row">
                <div class="col-1">
                  <b-img center src="/img/whatsapp.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11">
                  081389918678
                </div>
              </div>
            </a>
          </p>
        </div>
      </div>
    </div>
    <hr class="footer-1">
    <p class="text-center text-light"><b>Copyright © 2022 HRN Auto Care All Rights Reserved</b></p>
    <!-- END FOOOTER -->
  </div>

</template>

<style>
  .custom-card-body>.card-body {
    padding: 0 !important;
  }

  .custom-card-header {
    padding: 0 !important;
  }

  .custom-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .custom-col {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bg-grey {
    background-color: #222222;
  }

  .banner-image-servis-9 {
    background-image: url('/img/tips/4.jpeg');
    height: 100%;
    width: 100%;
    background-size: auto;
    /* background-attachment: fixed; */
  }

  .banner-image-servis-4 {
    background-image: url('/img/detailing.png');
    background-size: cover;
    height: 50vh;
    /* background-attachment: fixed; */
  }

  .banner-image-servis-3 {
    background-image: url('/img/servis.png');
    background-size: cover;
    height: 50vh;
    /* background-attachment: fixed; */
  }

  .card-body {
    background-color: black;
  }

  .card {
    background-color: black !important;
  }

  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
  }

  .my-float {
    margin-top: 10px;
    margin-left: 3px;
  }

  .jumbotron {
    background-color: #222222 !important;
    margin-bottom: 0 !important
  }

  a.workshop {
    color: #ffffff;
  }

  hr.footer-1 {
    border: 1px solid grey;
  }

  .banner-image {
    background-image: url('/img/banner2-1.png');
    background-size: cover;
    width: 100vw;
    height: 40vh;
    /* background-attachment: fixed; */
  }

  .mechanic {
    width: 100vw;
    height: 40vh;
    position: relative;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .jumbotron-blur {
    position: relative;
    width: 100vw;
    height: 65vh;
    overflow: hidden;
  }

  .jumbotron-blur .jumbo-content {
    position: relative;
    z-index: 2;
    color: #ffffff;
    top: 25vh;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .jumbotron-blur:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/img/banner1.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    /* filter: blur(5.5px);
    transform: scale(1.1); */
  }

  .custom-container {
    max-width: 600px !important;
  }
</style>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  export default {
    name: 'HomeView',
    components: {
      VueSlickCarousel
    },
    data() {
      return {
        settings: {
          "centerMode": true,
          "centerPadding": "20px",
          "focusOnSelect": true,
          "infinite": true,
          "slidesToShow": 3,
          "speed": 500,
          "responsive": [{
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                "infinite": true,
                "dots": true
              }
            },
            {
              "breakpoint": 600,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "initialSlide": 1
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
              }
            }
          ]
        }
      }
    },
    mounted() {
      window.scrollTo(0, 0)
    }
  }
</script>