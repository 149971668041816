<template>
  <div class="home">
    <!-- FLOATING BUTTON -->
    <a href="#" class="float">
      <a href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare" class="float"
        target="_blank">
        <b-img center src="/img/whatsapp.svg" width="40px" height="40px" alt="Center image" class="my-float"></b-img>
      </a>
    </a>
    <!-- END FLOATING BUTTON -->
    <!-- BANNER SESSION -->
    <div class="jumbotron-blur5 mb-5">
      <div class="jumbo-content">
        <div class="content">
          <b-img src="/img/hrn logo.png" class="mb-4" fluid alt="Fluid image" width="200px" height="200px"></b-img><br>
          <p class="h1"><b>PAKET DETAILING</b></p>
        </div>
      </div>
    </div>
    <!-- END BANNER SESSION -->
    <!-- PAKET SERVIS -->
    <div class="container text-light">
      <h2 class="m-5"><b>PROMO GRAND OPENING DISCOUNT UP TO 25% !!!</b></h2>
      <div class="row text-light">
        <!-- PAKET WARRIOR -->
        <div class="col-lg-4 mb">
          <b-img thumbnail fluid src="/img/car detailing.jpeg" alt="Image 1" class="mb-3"></b-img>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20CAR%20DETAILING.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0APaket%20Yang%20di%20Pilih%20%3A"
            target="_blank">
            <b-button variant="outline-warning mb-4">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET WARRIOR -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET MASTER -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/car detailing and coating.jpeg" alt="Image 1" class="mb-3"></b-img>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20CAR%20DETAILING%20DAN%20DETAILING.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0APaket%20Yang%20di%20Pilih%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MASTER -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET GRANDMASTER -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/motorcycle.jpeg" alt="Image 1" class="mb-3"></b-img>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20MOTORCYCLE%20DETAILING.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0APaket%20Yang%20di%20Pilih%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET GRANDMASTER -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
      </div>
    </div>
    <!-- END PAKET SERVIS -->
    <!-- FOOTER -->
    <hr>
    <div class="container text-light">
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <p class="text-left"><b>WORKSHOP</b></p>
          <p class="text-left"><a class="workshop" href="https://goo.gl/maps/H55BkPURVjNNeesY9" target="_blank">Jl. Raya
              Parung - Ciputat No.88, RT.01/RW.01, Bojongsari Baru, Kec. Bojongsari, Kota Depok, Jawa Barat 16517</a>
          </p>
        </div>
        <div class="col-lg-4">
          <p class="text-left"><b>JAM BUKA</b></p>
          <p class="text-left">Setiap hari<br> 09:00 - 18:00 WIB</p>
        </div>
        <div class="col-lg-4">
          <p class="text-left"><b>CONTACT US</b></p>
          <p class="text-left"><a class="workshop" href="https://www.instagram.com/hrnautocare/" target="_blank">
              <div class="row">
                <div class="col-1 float-left">
                  <b-img center src="/img/instagram.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11 float-right">
                  hrnautocare
                </div>
              </div>
            </a>
          </p>
          <p class="text-left"><a class="workshop" href="mailto:admin@hrnautocare.com" target="_blank">
              <div class="row">
                <div class="col-1">
                  <b-img center src="/img/email.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11">
                  admin@hrnautocare.com
                </div>
              </div>
            </a>
          </p>
          <p class="text-left"><a class="workshop"
              href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare"
              target="_blank">
              <div class="row">
                <div class="col-1">
                  <b-img center src="/img/whatsapp.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11">
                  081389918678
                </div>
              </div>
            </a>
          </p>
        </div>
      </div>
    </div>
    <hr class="footer-1">
    <p class="text-center text-light"><b>Copyright © 2022 HRN Auto Care All Rights Reserved</b></p>
    <!-- END FOOOTER -->
  </div>

</template>

<style>
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
  }

  .my-float {
    margin-top: 10px;
    margin-left: 3px;
  }

  .jumbotron {
    background-color: #222222 !important;
    margin-bottom: 0 !important
  }

  a.workshop {
    color: #ffffff;
  }

  hr.separator {
    border: 2px solid grey;
  }

  hr.footer-1 {
    border: 1px solid grey;
  }

  .mechanic {
    width: 100vw;
    height: 40vh;
    position: relative;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .jumbotron-blur5 {
    position: relative;
    width: 100vw;
    height: 65vh;
    overflow: hidden;
  }

  .jumbotron-blur5 .jumbo-content {
    position: relative;
    z-index: 2;
    color: #ffffff;
    top: 25vh;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .jumbotron-blur5:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/img/banner servis.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    /* filter: blur(5.5px);
    transform: scale(1.1); */
  }
</style>

<script>
  export default {
    name: 'HomeView',
    components: {

    },
    mounted() {
      window.scrollTo(0, 0)
    }
  }
</script>