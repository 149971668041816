<template>
    <div class="home">
        <!-- FLOATING BUTTON -->
        <a href="#" class="float">
            <a href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare"
                class="float" target="_blank">
                <b-img center src="/img/whatsapp.svg" width="40px" height="40px" alt="Center image" class="my-float">
                </b-img>
            </a>
        </a>
        <!-- END FLOATING BUTTON -->
        <!-- BANNER SESSION -->
        <div class="jumbotron-blur4 mb-5">
            <div class="jumbo-content">
                <div class="content">
                    <b-img src="/img/hrn logo.png" class="mb-4" fluid alt="Fluid image" width="200px" height="200px">
                    </b-img><br>
                    <p class="h1"><b>TENTANG</b></p>
                </div>
            </div>
        </div>
        <!-- END BANNER SESSION -->
        <!-- TENTANG -->
        <div class="container text-light">
            <h2 class="mt-5"><b>HRN AUTOCARE</b></h2>
            <h5 class="mb-5">FOR YOUR SPECIAL CAR</h5>
            <div class="row mb-5 text-justify">
                <div class="col-lg-6 mechanic">
                    <b-img thumbnail fluid src="/img/image1.png" alt="Image 1"></b-img>
                </div>
                <div class="col-lg-6">
                    <div class="d-flex h-100 flex-column">
                        <div class="container mt-auto mb-auto">
                            <p class="mb-5">HRN Autocare adalah bengkel mobil yang terpercaya dan kami menyediakan
                                servis yang sesuai dengan kebutuhan pelangan dengan paket-paket yang kami sediakan.
                                karena hal itu bengkel kami terjamin dari yang namanya hidden cost jadi pelangan tidak
                                khawatir dengan masalah budget yang disediakan. servis kami Detailing, Servis Berkala,
                                Overhaul, Tune Up, Gurah Mesin, Rem Kaki-Kaki, Injector Celaning, Ganti Oli, AC Mobil,
                                Servis, Transmisi, Power Steering</p>
                            <!-- <div class="row mb-5">
                                <div class="col-lg-6">
                                    <h5>VISI</h5>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint corporis distinctio
                                    assumenda quo
                                    saepe doloremque blanditiis hic quis. Optio repudiandae doloribus hic ducimus earum
                                    nulla vel
                                    magnam dolorum molestias voluptas.
                                </div>
                                <div class="col-lg-6">
                                    <div class="mt-auto mb-auto">
                                        <h5>MISI</h5>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint corporis
                                        distinctio assumenda
                                        quo saepe doloremque blanditiis hic quis. Optio repudiandae doloribus hic
                                        ducimus earum
                                        nulla vel magnam dolorum molestias voluptas.
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END TENTANG -->
        <!-- FOOTER -->
        <hr>
        <div class="container text-light">
            <div class="row mt-5 mb-5">
                <div class="col-lg-4">
                    <p class="text-left"><b>WORKSHOP</b></p>
                    <p class="text-left"><a class="workshop" href="https://goo.gl/maps/H55BkPURVjNNeesY9"
                            target="_blank">Jl. Raya
                            Parung - Ciputat No.88, RT.01/RW.01, Bojongsari Baru, Kec. Bojongsari, Kota Depok, Jawa
                            Barat 16517</a>
                    </p>
                </div>
                <div class="col-lg-4">
                    <p class="text-left"><b>JAM BUKA</b></p>
                    <p class="text-left">Setiap hari<br> 09:00 - 18:00 WIB</p>
                </div>
                <div class="col-lg-4">
                    <p class="text-left"><b>CONTACT US</b></p>
                    <p class="text-left"><a class="workshop" href="https://www.instagram.com/hrnautocare/"
                            target="_blank">
                            <div class="row">
                                <div class="col-1 float-left">
                                    <b-img center src="/img/instagram.svg" width="20px" height="20px"
                                        alt="Center image"></b-img>
                                </div>
                                <div class="col-11 float-right">
                                    hrnautocare
                                </div>
                            </div>
                        </a>
                    </p>
                    <p class="text-left"><a class="workshop" href="mailto:admin@hrnautocare.com" target="_blank">
                            <div class="row">
                                <div class="col-1">
                                    <b-img center src="/img/email.svg" width="20px" height="20px" alt="Center image">
                                    </b-img>
                                </div>
                                <div class="col-11">
                                    admin@hrnautocare.com
                                </div>
                            </div>
                        </a>
                    </p>
                    <p class="text-left"><a class="workshop"
                            href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare"
                            target="_blank">
                            <div class="row">
                                <div class="col-1">
                                    <b-img center src="/img/whatsapp.svg" width="20px" height="20px" alt="Center image">
                                    </b-img>
                                </div>
                                <div class="col-11">
                                    081389918678
                                </div>
                            </div>
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <hr class="footer-1">
        <p class="text-center text-light"><b>Copyright © 2022 HRN Auto Care All Rights Reserved</b></p>
        <!-- END FOOOTER -->
    </div>

</template>

<style>
    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #25D366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
        z-index: 1000;
    }

    .my-float {
        margin-top: 10px;
        margin-left: 3px;
    }

    .jumbotron {
        background-color: #222222 !important;
        margin-bottom: 0 !important
    }

    a.workshop {
        color: #ffffff;
    }

    hr.separator {
        border: 2px solid grey;
    }

    hr.footer-1 {
        border: 1px solid grey;
    }

    .mechanic {
        width: 100vw;
        height: 40vh;
        position: relative;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .jumbotron-blur4 {
        position: relative;
        width: 100vw;
        height: 65vh;
        overflow: hidden;
    }

    .jumbotron-blur4 .jumbo-content {
        position: relative;
        z-index: 2;
        color: #ffffff;
        top: 25vh;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .jumbotron-blur4:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("/img/banner tentang.png");
        background-size: cover;
        background-repeat: no-repeat;
        /* Set a specific height */
        min-height: 500px;

        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        /* filter: blur(5.5px);
    transform: scale(1.1); */
    }
</style>

<script>
    export default {
        name: 'HomeView',
        components: {

        },
        mounted() {
            window.scrollTo(0, 0)
        }
    }
</script>